/* eslint-disable react/no-unstable-nested-components */
import { Box, Flex, GridItem } from "@chakra-ui/react";
import { CellContext, ColumnDef, PaginationState } from "@tanstack/react-table";
import React, { useEffect, useMemo, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { MODULES } from "../../../../main/common/constants/modules";
import { getDateFormatted } from "../../../../main/common/utils/dateHelper";
import { Backdrop } from "../../../../main/components/Backdrop";
import { BadgeTooltip } from "../../../../main/components/BadgeTooltip";
import { CreateNewDataButton } from "../../../../main/components/CreateNewDataButton";
import {
  CustomBreadcrumb,
  IBreadcrumbProps,
} from "../../../../main/components/CustomBreadcrumb";
import { TableServer } from "../../../../main/components/DataTableServer";
import {
  getFiltersConsideringQuery,
  TableFilteredParams,
  TableSortingParams,
  useTableServer,
} from "../../../../main/components/DataTableServer/useTableServer";
import { StatusSubtitle } from "../../../../main/components/StatusSubtitle";
import { HLarge2xl } from "../../../../main/components/Tipography";
import { Wrapper } from "../../../../main/components/Wrapper";
import { ITotalTariff, tariffStatusLabel } from "../../../../types/main/tariff";
import { StatsView } from "../components/StatsView";
import { TariffDetailCell } from "../components/TariffDetailCell";
import { PopoverTariff } from "../components/tariffList/PopoverList";
import { formatTariffList } from "../functions/formatList";
import { ITotalValues } from "../interface/totalValues.interface";
import { allTariff } from "../service/TariffService";

export type ITariffList = {
  tariffId: string;
  biller: string;
  contract: number;
  channel: string;
  channelParentId: number | null | undefined;
  clientName: string;
  startDate: Date;
  endDate: Date;
  product: string;
  projectDescription: string | undefined;
  tariffIdentifier: string | undefined;
  tariffValue: number;
  totalTariff: ITotalTariff;
  invoiceId: number | undefined;
  status: string;
  statusObject: {
    status: "OPEN" | "CLOSED" | "CANCELED";
    date?: Date;
  };
  dateStatus: Date | undefined;
  idProject: string | undefined;
  remittanceIds?: string;
  discount: number;
  boletoNossoNumero: {
    nossoNumero: number;
  } | null;
  blobName?: string | null;
  hasBoleto?: boolean;
};

const StatusDescriptionCell = ({
  info,
}: {
  info: CellContext<ITariffList, any>;
}) => {
  const date = getDateFormatted(info.row.original.dateStatus);

  return (
    <BadgeTooltip
      label={`${info.getValue()} ${date} `}
      colorScheme={
        tariffStatusLabel[info.row.original.statusObject.status]?.color ??
        "gray"
      }
    />
  );
};

export const TariffList: React.FC = () => {
  const [tariffs, setTariffs] = useState<{
    data: ITariffList[];
    total: number;
  }>({
    data: [],
    total: 0,
  });

  const [isLoading, setIsLoading] = useState(false);
  const [isExporting, setIsExporting] = useState(false);
  const [totalValues, setTotalValues] = useState<ITotalValues>({
    totalDiscount: "",
    totalValue: "",
  });

  const [query] = useSearchParams();

  const {
    columnFiltersParams,
    columnSortingParams,
    paginationParams,
    paginationState,
    columnFiltersState,
    sortingState,
  } = useTableServer({
    pageCount: tariffs.total,
  });

  // const getTotalValues = async (filters: TableFilteredParams | undefined) => {
  //   const data = await calculateTotalValues(filters);
  //   setTotalValues(data);

  //   return data;
  // };

  const handleList = async (
    pagination?: PaginationState,
    filters?: TableFilteredParams,
    sorting?: TableSortingParams
  ) => {
    setIsLoading(true);
    // await getTotalValues(filters);
    const response = await allTariff({
      filters,
      pagination,
      sorting,
    });

    if (response.type === "success" && response.body) {
      const list = formatTariffList(response.body.data);
      setTariffs({
        data: list,
        total: response.body.lastPage,
      });
    }
    setIsLoading(false);
  };

  const columns = useMemo<ColumnDef<ITariffList, any>[]>(
    () => [
      {
        header: "Status",
        accessorKey: "status",
        enableColumnFilter: false,
        enableSorting: false,
        cell: (info) => <StatusDescriptionCell info={info} />,
      },
      {
        header: "ID da Tarifa",
        id: "tariffId",
        accessorKey: "tariffId",
        filterFn: "fuzzy",
      },
      {
        header: "Data do status",
        id: "dateStatus",
        accessorKey: "dateStatus",
        enableColumnFilter: false,
        enableSorting: false,
        cell: (info) => {
          return getDateFormatted(info.getValue());
        },
      },
      {
        header: "Data de criação",
        id: "createdAt",
        filterFn: "date",
        accessorKey: "createdAt",
        cell: (info) => {
          return getDateFormatted(info.getValue());
        },
      },
      {
        header: "Documento",
        id: "clientDocument",
        filterFn: "document",
        accessorKey: "clientDocument",
      },
      {
        header: "Identificador",
        id: "tariffIdentifier",
        accessorKey: "tariffIdentifier",
        filterFn: "fuzzy",
      },
      {
        header: "Valor",
        accessorKey: "tariffValue",
        cell: (info) => <TariffDetailCell info={info} />,
      },
      {
        header: "DESCONTO",
        accessorKey: "discount",
        cell: (info) => {
          return Number(info.getValue() || 0).toLocaleString("pt-br", {
            style: "currency",
            currency: "BRL",
          });
        },
      },
      {
        header: "Contrato",
        accessorKey: "contract",
      },
      {
        header: "Canal",
        accessorKey: "channel",
      },
      {
        header: "ID Canal Mãe",
        accessorKey: "channelParentId",
      },
      {
        header: "Cliente",
        accessorKey: "clientName",
        filterFn: "fuzzy",
      },
      {
        header: "Produto",
        accessorKey: "product",
        filterFn: "fuzzy",
      },
      {
        header: "Tarifador",
        accessorKey: "biller",
        filterFn: "fuzzy",
      },
      {
        header: "Fatura",
        accessorKey: "invoiceId",
      },
      {
        header: "PDF Gerado?",
        accessorKey: "blobName",
        enableSorting: false,
        cell: (info) => {
          return !!info.getValue() ? "Sim" : "Não";
        },
      },
      {
        header: "Projeto",
        accessorKey: "idProject",
      },
      {
        header: (info) => (
          <PopoverTariff
            refetch={handleList}
            table={info.table}
            onLoading={setIsExporting}
          />
        ),
        id: "actions",
        cell: (info) => (
          <PopoverTariff
            info={info}
            refetch={handleList}
            table={info.table}
            onLoading={setIsExporting}
          />
        ),
      },
    ],
    []
  );

  const breadcrumb: IBreadcrumbProps[] = [
    { description: "Faturamento", href: "" },
    { description: "Tarifas", href: "/tarifflist" },
  ];

  useEffect(() => {
    const queryFilter = getFiltersConsideringQuery(
      query,
      "invoiceId",
      columnFiltersParams
    );

    handleList(paginationParams, queryFilter, columnSortingParams);
  }, [columnFiltersParams, columnSortingParams, paginationParams, query]);

  return (
    <Wrapper>
      <GridItem rowSpan={1} colSpan={4}>
        <CustomBreadcrumb breadcrumb={breadcrumb} />
        <Flex justifyContent="space-between" alignItems="center">
          <HLarge2xl mb="32px" color="gray.600">
            Gerenciamento de Tarifas
          </HLarge2xl>
          <Box>
            <CreateNewDataButton
              module={MODULES.TARIFF}
              path="/managetariff"
              text="Cadastrar nova tarifa"
            />
          </Box>
        </Flex>
        <Box>
          <StatsView
            totalDiscount={totalValues.totalDiscount}
            totalValue={totalValues.totalValue}
            isLoading={isLoading}
          />
        </Box>
        <TableServer
          refresh={handleList}
          data={tariffs.data}
          defaultColumns={columns}
          isLoading={isLoading}
          columnFiltersState={columnFiltersState}
          paginationState={paginationState}
          sortingState={sortingState}
        />
        <StatusSubtitle />
      </GridItem>
      {isExporting && <Backdrop />}
    </Wrapper>
  );
};
