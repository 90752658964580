/* eslint-disable react/no-unstable-nested-components */
import { Flex } from "@chakra-ui/react";
import { CellContext, ColumnDef, PaginationState } from "@tanstack/react-table";
import React, { useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import { getDateFormatted } from "../../../../main/common/utils/dateHelper";
import { Backdrop } from "../../../../main/components/Backdrop";
import { BadgeTooltip } from "../../../../main/components/BadgeTooltip";
import {
  CustomBreadcrumb,
  IBreadcrumbProps,
} from "../../../../main/components/CustomBreadcrumb";
import { DueDateCell } from "../../../../main/components/DataTable/DueDateCell";
import { TableServer } from "../../../../main/components/DataTableServer";
import {
  TableFilteredParams,
  TableSortingParams,
  useTableServer,
} from "../../../../main/components/DataTableServer/useTableServer";
import { StatusSubtitle } from "../../../../main/components/StatusSubtitle";
import { HLarge2xl } from "../../../../main/components/Tipography";
import { Wrapper } from "../../../../main/components/Wrapper";
import {
  IInvoiceDataList,
  IInvoiceFilters,
} from "../../../../types/main/invoice";
import { tariffStatusLabel } from "../../../../types/main/tariff";
import { InvoicePopover } from "../components/invoicePopover";
import { InvoicesFilters } from "../components/invoicesFilters";
import { getWarningFromInvoiceList } from "../functions/cells";
import { getAllInvoices } from "../service/InvoiceService";

const StatusDescriptionCell = ({
  info,
}: {
  info: CellContext<IInvoiceDataList, any>;
}) => {
  const value = info.getValue();
  const { original } = info.row;
  const date = getDateFormatted(original.invoiceStatus.date);
  const { status } = original.invoiceStatus;

  return (
    <BadgeTooltip
      label={`${value} ${date}`}
      colorScheme={tariffStatusLabel[status]?.color ?? "gray"}
    />
  );
};

export const InvoiceList: React.FC = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isExporting, setIsExporting] = useState(false);

  const [invoices, setInvoices] = useState<{
    data: IInvoiceDataList[];
    total: number;
  }>({
    data: [],
    total: 0,
  });

  const query = useLocation();

  const id = useMemo(
    () => new URLSearchParams(query.search).get("id"),
    [query.search]
  );

  const handleInvoicesList = async (
    filters?: IInvoiceFilters,
    pagination?: PaginationState,
    columnFilters?: TableFilteredParams,
    sorting?: TableSortingParams
  ) => {
    setIsLoading(true);
    const response = await getAllInvoices({
      filters,
      pagination,
      columnFilters,
      sorting,
    });

    if (response.type === "success" && response.body) {
      setInvoices({
        data: response.body.data,
        total: response.body.lastPage,
      });
    }
    setIsLoading(false);
  };

  const {
    columnFiltersParams,
    columnSortingParams,
    paginationParams,
    columnFiltersState,
    paginationState,
    sortingState,
  } = useTableServer({
    pageCount: invoices.total,
  });

  const columns = useMemo<ColumnDef<IInvoiceDataList, any>[]>(
    () => [
      {
        header: "Status",
        accessorFn: (row) =>
          tariffStatusLabel[row.invoiceStatus.status]?.description ??
          "Status não disponível no momento",
        cell: (info) => <StatusDescriptionCell info={info} />,
        enableSorting: false,
        minSize: 50,
        enableColumnFilter: false,
      },
      {
        header: "ID",
        id: "invoiceId",
        accessorKey: "invoiceId",
      },
      {
        header: "Cliente",
        id: "clientName",
        accessorKey: "clientName",
      },
      {
        header: "Categoria Produto",
        id: "productCategoryName",
        accessorKey: "productCategoryName",
      },
      {
        header: "Produtos",
        id: "productsNameList",
        accessorKey: "productsNameList",
      },
      {
        header: "Projeto",
        accessorKey: "projectId",
      },
      {
        header: "Contrato",
        accessorKey: "contract",
        cell: (info) => {
          const total = info.getValue()?.length ?? 0;
          if (total > 1) return `${total} Contratos`;
          return info.getValue();
        },
      },
      {
        header: "Canais",
        accessorKey: "channel",
        cell: (info) => {
          const total = info.getValue()?.length ?? 0;
          if (total > 1) return `${total} Canais`;
          return info.getValue();
        },
      },
      {
        header: "Prestador",
        accessorKey: "provider",
      },
      {
        header: "Vencimento",
        accessorKey: "dueDate",
        enableSorting: false,
        enableColumnFilter: false,
        cell: (info) => {
          const warning = getWarningFromInvoiceList(info.row.original);

          return <DueDateCell dueDate={info.getValue()} warning={warning} />;
        },
      },
      {
        header: "Valor Fatura",
        accessorKey: "invoicesValue",
        enableSorting: false,
        enableColumnFilter: false,
        cell: (info) =>
          Number(info.getValue()).toLocaleString("pt-BR", {
            style: "currency",
            currency: "BRL",
          }),
      },
      {
        header: "Valor Juros",
        accessorKey: "invoicesInterest",
        enableSorting: false,
        enableColumnFilter: false,
        cell: (info) =>
          Number(info.getValue()).toLocaleString("pt-BR", {
            style: "currency",
            currency: "BRL",
          }),
      },
      {
        header: "Valor Multas",
        accessorKey: "invoicesFine",
        enableSorting: false,
        enableColumnFilter: false,
        cell: (info) =>
          Number(info.getValue()).toLocaleString("pt-BR", {
            style: "currency",
            currency: "BRL",
          }),
      },
      {
        header: "Desconto",
        accessorKey: "invoicesDiscount",
        enableSorting: false,
        enableColumnFilter: false,
        cell: (info) =>
          Number(info.getValue()).toLocaleString("pt-BR", {
            style: "currency",
            currency: "BRL",
          }),
      },
      {
        header: "Valor Total",
        accessorKey: "invoicesTotalValue",
        enableSorting: false,
        enableColumnFilter: false,
        cell: (info) =>
          Number(info.getValue()).toLocaleString("pt-BR", {
            style: "currency",
            currency: "BRL",
          }),
      },
      {
        header: "Email Enviado?",
        accessorKey: "emailSent",
        enableSorting: false,
        cell: (info) => (info.getValue() ? "Sim" : "Não"),
      },
      {
        header: "NFe Enviado?",
        accessorKey: "nfeSent",
        enableSorting: false,
        enableColumnFilter: true,
        cell: (info) => (info.getValue() ? "Sim" : "Não"),
      },
      {
        header: "NFe Gerada?",
        accessorKey: "nfeGenerated",
        enableSorting: false,
        enableColumnFilter: true,
        cell: (info) => (info.getValue() ? "Sim" : "Não"),
      },
      {
        header: "PDF Gerado?",
        accessorKey: "blobName",
        enableSorting: false,
        cell: (info) => (!!info.getValue() ? "Sim" : "Não"),
      },
      {
        header: (info) => (
          <InvoicePopover
            refetch={handleInvoicesList}
            table={info.table}
            onLoading={setIsExporting}
          />
        ),
        id: "actions",
        cell: (info) => (
          <InvoicePopover
            info={info}
            refetch={handleInvoicesList}
            table={info.table}
            onLoading={setIsExporting}
          />
        ),
      },
    ],
    []
  );

  useEffect(() => {
    const filters = {
      ...columnFiltersParams,
      invoiceId: columnFiltersParams?.invoiceId ?? id,
    };

    handleInvoicesList(
      undefined,
      paginationParams,
      filters,
      columnSortingParams
    );
  }, [columnFiltersParams, columnSortingParams, id, paginationParams]);

  const breadcrumb: IBreadcrumbProps[] = [
    {
      description: "Faturamento",
      href: "/",
    },
    {
      description: "Faturas",
      href: "/invoiceList",
    },
  ];

  return (
    <Wrapper>
      <CustomBreadcrumb breadcrumb={breadcrumb} />
      <HLarge2xl color="gray.600">Faturas</HLarge2xl>
      <InvoicesFilters handleInvoicesList={handleInvoicesList} />
      <Flex justifyContent="flex-end" alignItems="center" mb="1rem" />
      <TableServer
        refresh={() => handleInvoicesList()}
        data={invoices.data}
        defaultColumns={columns}
        isLoading={isLoading}
        columnFiltersState={columnFiltersState}
        paginationState={paginationState}
        sortingState={sortingState}
      />
      <StatusSubtitle />
      {isExporting && <Backdrop />}
    </Wrapper>
  );
};
